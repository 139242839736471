<!--
Counter / Contact
-->
<template>
  <div class="container-fluid py-4 bg-us2">
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-lg-6 py-4">
          <div class="row">
            <div class="col-lg-12 px-5">
              <div
                class="about-us bg-yellow text-blue fw-bold float-end text-center text-uppercase" v-html="$t('modules.innumbers')" />
            </div>
          </div>
          <!--carousel-->

          <div class="container py-4">
            <div class="row clearfix">
              <div class="col-md-2"></div>
              <div class="col-md-10">
                <swiper class="swiper" :options="swiperOption">
                  <swiper-slide v-for="(item, index) in this.data" :key="index">
                    <div class="item">
                      <div class="text-center">
                        <div class="bg-txt-yellow">{{ item.number }}</div>
                        <div class="text-white text-center text-uppercase">
                          {{ item.text }}
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                  <div
                    class="swiper-pagination numbers"
                    slot="pagination"
                  ></div>
                </swiper>
              </div>
            </div>
            <!--carousel-->
          </div>
        </div>

        <div class="col-lg-6" v-if="this.configData">
          <div class="col-xxl-10 px-sm-5 py-4">
            <h1 class="col-12 my-1 text-yellow text-capitalize">{{$t('modules.contact')}}</h1>
            <div class="text-lightblue fs-7 py-4">
              {{ this.configData.contact }}
            </div>
            <div class="text-white social">
              <button
                type="button"
                class="btn bg-yellow fw-medium"
                @click.prevent.stop="contactToggle"
              >{{$t('modules.write')}}
              </button>
              <span class="px-1">{{$t('modules.or')}}</span>
              <a :href="this.configData.facebook" target="_blank"
                ><img
                  src="../../../assets/images/icon-facebook.png"
                  class="px-1"
                  style="max-width: 60px"
              /></a>
              <a :href="this.configData.linkedin" target="_blank"
                ><img
                  src="../../../assets/images/icon-linkedin.png"
                  class="px-1"
                  style="max-width: 60px"
              /></a>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div v-show="this.visible.contactVisible">
      <GetInTouch @toggle="contactToggle" />
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import GetInTouch from "@/components/partials/GetInTouch";

export default {
  name: "module6",
  components: {
    GetInTouch,
    Swiper,
    SwiperSlide,
  },
  props: {
    data: { data: [Array] },
    configData: { data: [Array] },
  },
  data() {
    return {
      visible: {
        contactVisible: false,
      },
      swiperOption: {
        slidesPerView: 1,
        keyboard: {
          enabled: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 1000,
        },
        speed: 700,
      },
    };
  },
  methods: {
    contactToggle() {
      this.visible.contactVisible = !this.visible.contactVisible;
      this.showCollapse = false;
    },
  },
};
</script>

<style scoped></style>
